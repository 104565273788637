import { graphql } from "gatsby";
import LandingPage from "../components/landingpage";
import React, { useState, useEffect } from "react"
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { getPageUrlOutOfSanityReference, toPlainText } from "../lib/helpers";
import AuthenticatorCompleteProfile from "../components/auth0/AuthenticatorCompleteProfile";
import Authenticator from "../components/auth0/Authenticator";
import { navigate } from "gatsby";
import jwtDecode from "jwt-decode";
import { ApiProvider } from '../components/auth0/ApiContext';
import { Helmet } from 'react-helmet';

const publishedAt = new Date().toISOString()


export const query = graphql`
query LandingPageTemplateQuery($id: String!) {
  post: sanityLandingPage(id: {eq: $id}) {
    ... fragLandingPage
    gatedContentBody {
      ... fragSanityCourseTitle
      ... fragSanityContentCardBigBlockGroup
      ... fragSanityAvailableCourses
      ... fragSanityTabsTextboxBlock
      ... fragSanityDropdownWidget
      ... fragSanitySocialMediaPosts
      ... fragSanityTwitterBlock
      ... fragSanityLandingSearchBlock
      ... fragSanityBestBuysTable
      ... fragSanityRecProviders
      ... fragSanityTipChooser
      ... fragSanityBreadcrumb
      ... fragSanityPopularConversationsBlock
      ... fragSanityBigBlockGroup
      ... fragSanityHeroSection
      ... fragSanityHeaderBlock
      ... fragSanityTitleSection
      ... fragSanityLatestArticlesBlock
      ... fragSanityCalcReference
      ... fragSanityFilteredQuestionsBlock
      ... fragSanityAdvisersList
      ... fragSanityFundList
      ... fragSmallSpacerBlock
      ... fragWidgetSelection
      ... fragSanityEmailCaptureForm
      
   }
   content {
      ... fragSanityCourseTitle
      ... fragSanityAvailableCourses
      ... fragSanityTabsTextboxBlock
      ... fragSanityDropdownWidget
      ... fragSanitySocialMediaPosts
      ... fragSanityTwitterBlock
      ... fragSanityLandingSearchBlock
      ... fragSanityContentCardBigBlockGroup
      ... fragSanityBestBuysTable
      ... fragSanityRecProviders
      ... fragSanityTipChooser
      ... fragSanityBreadcrumb
      ... fragSanityPopularConversationsBlock
      ... fragSanityBigBlockGroup
      ... fragSanityDataCaptureForm
      ... fragSanityHeroSection
      ... fragSanityHeaderBlock
      ... fragSanityTitleSection
      ... fragSanityLatestArticlesBlock
      ... fragSanityCalcReference
      ... fragSanityFilteredQuestionsBlock
      ... fragSanityAdvisersList
      ... fragSanityFundList
      ... fragSmallSpacerBlock
      ... fragWidgetSelection
      ... fragSanityEmailCaptureForm
      ... fragSanitySurveyWidget
      ... fragSanityWealthWizard
   }
  }
  advList: allSanityPeople(
    filter: {publishedAt: {ne: null}, showAdviser: {eq: true}}
    sort: {order: DESC, fields: [answeredQuestionsRank, dateAnsweredRank]}
  ) {
    nodes {
      ... fragAdviserList
    }
  }
  dictionary: allSanityDictionary {
    nodes {
       ... dictionaryFragment
    }
  }
   allArticles: allSanityPost (sort: {order: DESC, fields: [publishedAt]}
     limit: 200
     filter: {publishedAt: {ne: null, lt: "2030-01-01"}}
   ){
     nodes {
          ... fragAllArticles
     }
   }
}
`;

const LandingPageTemplate = (props) => {
  const { data, errors } = props;
  const [emailCaptured, setEmailCaptured] = useState(false);
  const post = data && data.post;
  const getPageUrl = getPageUrlOutOfSanityReference(post)
  const [loading, setLoading] = useState(true);

  post.dictionary = data && data.dictionary;
  post.advList = data.advList;
  post.allArticles = data && data.allArticles

  var auth = true;
  if (post.isPrivatePage) {
    auth = Authenticator();
  }

  const [getToken, setToken] = useState(typeof window !== "undefined" ? localStorage.getItem("id_token") : []);
  const [getProfile, setProfile] = useState(typeof window !== "undefined" ? localStorage.getItem("profile") : []);

  const [getProfileSet, setProfileSet] = useState(null);
  const [getPage, setPage] = useState(null)

  useEffect(() => {
    const userProfile = localStorage.getItem('profile');
    const loggedIn = localStorage.getItem('id_token');

    setToken(loggedIn);

    var isProfileSet = userProfile?.toLowerCase() == "false" ? "1" : userProfile?.toLowerCase()

    localStorage.setItem("profile", isProfileSet)

    setProfileSet(isProfileSet);
    setPage(window.location.pathname);
  }, []);

  if (getProfileSet === null) {
    // You might show a loading state here while checking the authentication
    return null;
  }


  //console.log("getToken1", getToken, "getProfile", getProfile);

  //useEffect(() => {

  //  //if (typeof window !== "undefined" && getToken) {

  //  //  const tokenData = jwtDecode(getToken);
  //  //  const profileVal = getProfile;

  //  //  console.log('profileVal landingpages', profileVal)
  //  //  console.log('tokenData.email_verified', tokenData.email_verified)

  //console.log("post._rawExcerpt", post._rawExcerpt)

  if (auth) {
    try {

      return (
        <>
        <AuthenticatorCompleteProfile getProfileSet={getProfileSet} fromPage={getPage} getToken={getToken} privatePage={post.isPrivatePage}>
          <Layout hideFooter={post.enableGatedContent && !emailCaptured} isCoursePage={post.theme == "Boring Money Campus theme" ? true : false}>
            {errors && <SEO title="GraphQL Error" />}
            {post && (
              <SEO
                title={post.title || "Boring Money"}
                description={toPlainText(post._rawExcerpt)}
                image={post.mainImage}
                canonical={!post.canonicalTagOverride && getPageUrl}
                canonicalTagOverride={post.canonicalTagOverride}
              />
            )}

            {errors && (
              <Container>
                <GraphQLErrorList errors={errors} />
              </Container>
            )}
            <ApiProvider>
              {post && <LandingPage emailCaptured={emailCaptured} setEmailCaptured={setEmailCaptured} hideFooter={post.enableGatedContent && !emailCaptured} {...post} />}
            </ApiProvider>
          </Layout>
        </AuthenticatorCompleteProfile>

      
        </>
      );
    } catch (e) {
      console.log("LandingPageTemplate", e);
      return (null);
    }
  }
  else {
    return null;
  }

};

export default LandingPageTemplate;
