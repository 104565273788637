import React from 'react';
import { navigate } from "gatsby";


const AuthenticatorCompleteProfile = ({ getProfileSet, children, fromPage, getToken, privatePage }) => {

  console.log("AuthenticatorCompleteProfile", getProfileSet, fromPage, privatePage)

  if (!getToken) {
    //no token, not logged in
   // console.log("AuthenticationComponent", "not logged in");
    return <>{children}</>;
  }

  if (fromPage === "/") {
    //homepage, don't redirect
   console.log("AuthenticationComponent", "home");
    return <>{children}</>;
  }

  if (fromPage.includes("/complete-profile") && getProfileSet !=="complete") {

    //already completed profile page (pre enhanced form) or new profile
    console.log("AuthenticationComponent", "1");
    return <>{children}</>;
    //var url = "/members"
    //navigate(url);
    //return null;
  }
  else if (fromPage.includes("/complete-profile") && getProfileSet =="complete") {

    //on profile page, have already completed
    var url = "/members"
    navigate(url);
    return null;
  }
  else {
    
    //profile set and not an excluded route
    if (getProfileSet == "complete") {

      console.log("COMPLETE PAGE")
      return <>{children}</>;
    }
    else {

      //if getProfileSet == true - these are existing users, who have given firstname/surname and not completed the full form.
     

      //page is private 
      if (privatePage) {

        console.log("53 - HERE")
        console.log("AuthenticatorCompleteProfile", getProfileSet, fromPage)


        //profile not complete and coming from landingPage that is private
        if (!fromPage.includes("/complete-profile") && getProfileSet !== "complete") {

          console.log("57")

          localStorage.setItem("returnUrl", fromPage)
          var url = "/complete-profile"

          navigate(url)

          return null;
        }

        if (fromPage.includes("/complete-profile") && getProfileSet !== "complete") {

          console.log("72")
          return <>{children}</>;
        }
       
       
        if (!fromPage.includes("/complete-profile") && getProfileSet == "complete")
        {
          return <>{children}</>;
        }
      }
      else {
        //none of the above, just show the page.
        return <>{children}</>;
      }
      
      
    }
  }

  
};

export default AuthenticatorCompleteProfile;
